import { API_PATH } from "../../constants/nextEnv";
import clientfetch from "../../helpers/clientfetch";

export async function syncUser(data = {}) {
  // sync data with cat-crouch
    try {
      const r = await clientfetch(
        `${API_PATH}/user/sync`,
        {
          body: JSON.stringify(data),
          headers: { "Content-Type": "application/json" },
          method: "POST",
        }
      )
      return r
    } catch(e) {
      // not throwing user data sync error for now
      throw e;
    }
}

import { SYNC_FAIL_MSG } from "../../constants/errorMsg";
import clientfetch from "../../helpers/clientfetch";
import { syncUser } from "./syncUser";

export async function signup(body) {
  const opts = {
    body: JSON.stringify(body),
    headers: { "Content-Type": "application/json" },
    method: "POST",
  };

  let response
  try {
    response = await clientfetch(`/api/signup`, opts)
  } catch(e) {
    throw e
  }
  // sync data with cat-crouch
  const {data} = response
  if (data) {
    let reSync = 0;
    let syncResult = {};
    while (reSync < 2){
      try {
        syncResult = await syncUser(data)
        if (syncResult.error) {
          reSync++
        } else {
          break;
        }
      } catch(e){
        console.log(e);
        reSync++;
      }
    }
    if (syncResult.error) {
      return {error: SYNC_FAIL_MSG}
    }
  }
  return response
}
import Formask from "formask";
import { signup } from "../../services/next/signup";
import styled from "styled-components";
import InputWrapper from "../style/InputWrapper";
import ErrorWrapper from "../style/ErrorWrapper";
import queryString from 'query-string'
import { user } from "../../helpers/clientStorage";
import Input from "../interact/Input";
import SvgMail from "../icons/Mail";
import SvgPassword from "../icons/Password";
import Button from "../interact/Button";
import { BUTTON_STYLE_MAIN } from "../../constants/style";
import { useRouter } from "next/router";
import { useState } from "react";
import { EMAIL } from "../../constants/misc";

const FormWrapper = styled.form`
`

const ButtonWrapper = styled.div`
  display: flex;
  font-size: 20px;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
`

const FieldWrapper = styled.main`
  margin: 0 0 20px 0;
  ${InputWrapper} {
    margin: 10px 0;
    padding: 10px 0;
  }
`;

const InputFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
`

const onSubmit = async (values, formaskProps, { i18n, isModal, onLoginSuccess, router, setError }) => {
  const { setIsSubmitting } = formaskProps
  try {
    const body = {
      ...values,
      type: EMAIL
    }
    if (!body.password || !body.email  ) {
      setError(i18n["EMAIL_AND_PASSWORD_REQUIRED"])
      setIsSubmitting(false)
      return;
    }
    const res = await signup(body)
    if(res.error) {
      setError(res.error);
      return
    }
    user.set(res.data)
    const { from } = queryString.parse(window.location.search) || {}
    if (!isModal) { router.replace(from ? from : '/') }
    onLoginSuccess()
  } catch(e) {
    return;
  }
  
}

export default function SignupForm({ i18n, isModal, onLoginSuccess }) {
  const router = useRouter()
  const [error, setError] = useState('')
  return (
    <Formask
      onSubmit={(values, formaskProps) => onSubmit(values, formaskProps, { i18n, isModal, onLoginSuccess, router, setError })}
      render={formaskProps => {
        const { hook, submitHandler } = formaskProps;
        return (
          <FieldWrapper>
            <FormWrapper
              onSubmit={submitHandler}
            >
              <InputWrapper>
                <label>
                  <SvgMail />
                </label>
                {
                  hook('email')(
                    <Input
                      FieldWrapper={InputFieldWrapper}
                      bgColor="#fff"
                      placeholder={i18n['EMAIL']}
                      type="email"
                      width="100%"
                    />
                  )
                }
              </InputWrapper>
              <InputWrapper>
                <label>
                  <SvgPassword />
                </label>
                {
                  hook('password')(
                    <Input
                      FieldWrapper={InputFieldWrapper}
                      bgColor="#fff"
                      placeholder={i18n['PASSWORD']}
                      type='password'
                      width="100%"
                    />
                  )
                }
              </InputWrapper>
              <ErrorWrapper>{error}</ErrorWrapper>
              <ButtonWrapper>
                <Button
                  {...BUTTON_STYLE_MAIN}
                  type='submit'
                >
                  {i18n['SIGNUP']}
                </Button>
              </ButtonWrapper>
            </FormWrapper>
          </FieldWrapper>
        )
      }}
    />
  )
}
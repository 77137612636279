import styled from "styled-components";
import { NEGATIVE_HIGHLIGHT_COLOR } from "../../constants/style";


const ErrorWrapper = styled.div`
  display: flex;
  font-size: 18px;
  justify-content: center;
  color: ${NEGATIVE_HIGHLIGHT_COLOR};
`

export default ErrorWrapper